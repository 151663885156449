<template>
  <div class="content-wrapper">
    <div class="register-container">
      <img src="../assets/homepage_logo.png" alt="logo">
      <h1>Welcome! Let's get you setup.</h1>
        <label>Username</label>
        <input type="text" />
        <label>Email</label>
        <input type="text" v-model="email" />
        <label>Confirm Email</label>
        <input type="text" />
        <label>Password</label>
        <input type="password" v-model="password" />
        <label>Confirm Password</label>
        <input type="password" />
      <va-button class="btn-register" @click="register">Create Account</va-button>
      <span>Already have an account?</span>
      <p><va-button class="btn-login" to="/login">Login</va-button></p>
    </div>  
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import firebase from 'firebase';
  import { useRouter } from 'vue-router';

  const email = ref('');
  const password = ref('');
  const router = useRouter();
  
  const register = () => {
    firebase
      .auth()
      .createUserWithEmailAndPassword(email.value, password.value) // need .value because ref()
      .then(() => {
        router.push('/')
      })
      .catch(error => {
        console.log(error.code)
        alert(error.message);
      });
  }
</script>

<style scoped>
.content-wrapper {
  display: flex;
  justify-content: center;
}

.register-container {
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-content: center;
  width: 350px;
  border-radius: 8px;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.2);
  padding: 20px;
}
  .register-container > img {
    width: 200px;
    align-self: center;
    margin: 10px 0 20px 0;
  }
  .register-container > h1 {
    margin-bottom: 10px;
  }

  .register-container > label {
    text-align: left;
    font-size: 14px;
    margin: 15px 0 4px 0;
    color: #6D6D6D;

  }
  .register-container > input {
    background: #F3F5FA;
    border: none;
    border-radius: 4px;
    height: 24px;
  }

  .btn-register {
    margin: 20px 0 20px 0;
  }
  .btn-login {
    margin: 20px 0 10px 0;
  }

</style>